<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first">
        <span slot="label">退款申请</span>
        <div v-if="orderlistlength != 0">
          <div style="padding: 10px">
            <el-table
              ref="multipleTable"
              :data="orderlist"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="文章标题" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.title }}</p>
<!--                      <p>-->
<!--                        <span>{{ scope.row.name }}</span-->
<!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
<!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="退款申请时间" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.applytime }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="退款时间" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.handletime }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="退款金额" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.refund_amount }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="退款状态" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.isdhandleString }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div>
						<div class="orderlist" v-for="(item,key) in orderlist" @click="showDetail(item.id)">
							<div class="ygdleft">
								<p class="biaoti">{{item.title}}</p>
								<p><span>{{item.name}}</span><span>{{item.editor}}</span></p>
							</div>
							<div class="ygdright">
								<div class="urgent" v-show="item.attribute==1">
									加急
								</div>
								<div style="height: 20px;"></div>
								<span> <i class="el-icon-s-promotion"></i>投稿时间：{{item.Contribution_time}}</span>
							</div>
						</div>
					</div -->
            <div
              class="ygdPage"
              style="
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
              "
            >
              <el-pagination
                class="fy"
                small
                @size-change="handleSizeChange"
                :page-size="pagesize"
                :page-sizes="[10, 20, 30]"
                layout="total, sizes, prev, pager, next, jumper"
                @current-change="current_change"
                :total="total"
                background
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="second">
        <span slot="label">提现申请</span>
        <div v-if="orderlistlength1 != 0">
          <div style="padding: 10px">
            <el-table
              ref="multipleTable"
              :data="orderlist1"
              tooltip-effect="dark"
              style="width: 100%"
            >


              <el-table-column label="申请提现时间" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.withdrawTime }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="提现时间" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.handletime }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="提现金额" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.num }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="提现状态" sortable show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="ordertable">
                    <div class="ygdleft">
                      <p class="biaoti">{{ scope.row.withdrawStateString }}</p>
                      <!--                      <p>-->
                      <!--                        <span>{{ scope.row.name }}</span-->
                      <!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div
            class="ygdPage"
            style="
              display: flex;
              justify-content: center;
              width: 100%;
              margin-top: 20px;
            "
          >
            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange2"
              :page-size="pagesize2"
              :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change2"
              :total="total2"
              background
            >
            </el-pagination>
          </div>
        </div>
        <div v-else>
          <div
            style="
              text-align: center;
              color: #999999;
              padding: 20px;
              width: 100%;
            "
          >
            暂无数据
          </div>
        </div>
      </el-tab-pane>
<!--      <el-tab-pane name="third">-->
<!--        <span slot="label">待确认（{{ total3 }}）</span>-->
<!--        <div v-if="orderlistlength2 != 0">-->
<!--          <div style="padding: 10px">-->
<!--            <el-table-->
<!--              ref="multipleTable"-->
<!--              :data="orderlist2"-->
<!--              tooltip-effect="dark"-->
<!--              style="width: 100%"-->
<!--            >-->
<!--              <el-table-column label="文章标题" sortable show-overflow-tooltip>-->
<!--                <template slot-scope="scope">-->
<!--                  <div class="ordertable">-->
<!--                    <div class="ygdleft">-->
<!--                      <p class="biaoti">{{ scope.row.title }}</p>-->
<!--                      <p>-->
<!--                        <span>{{ scope.row.name }}</span-->
<!--                        >&nbsp;<span>{{ scope.row.editor }}</span>-->
<!--                      </p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                show-overflow-tooltip-->
<!--                label="按投稿时间排序"-->
<!--                prop="Contribution_time"-->
<!--                align="center"-->
<!--                sortable-->
<!--              >-->
<!--                <template slot-scope="scope">-->
<!--                  <div class="ordertable">-->
<!--                    <div class="ygdright">-->
<!--                      <div style="height: 20px"></div>-->
<!--                      <span-->
<!--                        ><i class="el-icon-s-promotion"></i>投稿时间：{{-->
<!--                          scope.row.Contribution_time-->
<!--                        }}</span-->
<!--                      >-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                show-overflow-tooltip-->
<!--                label="按属性排序"-->
<!--                prop="attribute"-->
<!--                sortable-->
<!--              >-->
<!--                <template slot-scope="scope">-->
<!--                  <div class="ordertable">-->
<!--                    <div class="ygdright">-->
<!--                      <div style="height: 20px"></div>-->
<!--                      <span-->
<!--                        @click="showDetail(scope.row.id)"-->
<!--                        style="cursor: pointer"-->
<!--                        >查看详情</span-->
<!--                      >-->
<!--                      <div class="urgent" v-if="scope.row.attribute == 1">-->
<!--                        加急-->
<!--                      </div>-->
<!--                      <div class="nourgent" v-if="scope.row.attribute == 0">-->
<!--                        普通-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--            <div-->
<!--              class="ygdPage"-->
<!--              style="-->
<!--                display: flex;-->
<!--                justify-content: center;-->
<!--                width: 100%;-->
<!--                margin-top: 20px;-->
<!--              "-->
<!--            >-->
<!--              <el-pagination-->
<!--                class="fy"-->
<!--                small-->
<!--                @size-change="handleSizeChange3"-->
<!--                :page-size="pagesize3"-->
<!--                :page-sizes="[10, 20, 30]"-->
<!--                layout="total, sizes, prev, pager, next, jumper"-->
<!--                @current-change="current_change3"-->
<!--                :total="total3"-->
<!--                background-->
<!--              >-->
<!--              </el-pagination>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <div-->
<!--            style="-->
<!--              text-align: center;-->
<!--              color: #999999;-->
<!--              padding: 20px;-->
<!--              width: 100%;-->
<!--            "-->
<!--          >-->
<!--            暂无数据-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import { costManager } from "../../../API/ZZD/costManager.js"
export default {
  components: {

  },
  data () {
    return {
      activeName: 'first',
      orderlist: [],
      orderlist1: [],
   //   orderlist2: [],
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      total2: 0, //默认数据总数
      pagesize2: 10, //每页的数据条数
      currentPage2: 1, //默认开始页面
      total3: 0, //默认数据总数
      pagesize3: 10, //每页的数据条数
      currentPage3: 1, //默认开始页面
      now: "",
      orderlistlength: 0,
      orderlistlength1: 0,
      orderlistlength2: 0,
    }


  },
  mounted () {

    this.selecttab()
    this.payorder()
    this.qrorder()
    this.juorder()

  },
  methods: {
    handleClick (tab, event) {
      console.log(tab.index);
      this.now = tab.index
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.payorder()
    },
    handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.payorder()
    }, // 分页
    current_change2: function (currentPage) {
      this.currentPage2 = currentPage;
      this.juorder()
    },
    handleSizeChange2 (val) {
      console.log(val);
      this.pagesize2 = val;
      this.juorder()
    }, // 分页
    current_change3: function (currentPage) {
      this.currentPage3 = currentPage;
      this.qrorder()
    },
    handleSizeChange3 (val) {
      console.log(val);
      this.pagesize3 = val;
      this.qrorder()
    },
    showDetail (wid, attr,isUseFws) {
		console.log(wid,attr,isUseFws,'shenqg--------------------------')
      this.$router.push({ path: "/Pending/todoorder/detail", query: { "id": 0, "now": this.now, 'wid': wid ,isUseFws:isUseFws} })
    }, showbhDetail (wid, attr,isUseFws) {
      if (attr == 54 || attr == 55) {//申请改变服务商的订单
        this.$router.push({ path: "/Pending/todoorder/changeDetail", query: { "id": 0, "now": this.now, 'wid': wid, } })
      } else if (attr == 44 || attr == 45) {//申请退款的订单
        this.$router.push({ path: "/Pending/todoorder/drawbackdetail", query: { "id": 0, "now": this.now, 'wid': wid } })
      } else {//被驳回未处理的订单包括服务商被驳回用attr控制
        this.$router.push({ path: "/Pending/todoorder/detail", query: { "id": 0, "now": this.now, 'wid': wid, attr: attr } })
      }
    }, selecttab () {
      if (this.$route.query.now) {
        console.log("zhixing ")
        this.now = this.$route.query.now
        if (this.now == 0) {
          this.activeName = "first"
        } else if (this.now == 1) {
          this.activeName = "second"
        } else if (this.now == 2) {
          this.activeName = "third"
        }
      }
    },
    payorder () {

      var id = localStorage.getItem('ZZId');
      costManager(id, this.currentPage, this.pagesize, 1).then(res => {

        console.log(res,'idididididiidididzuozhe')
        this.total = res.data.total

        this.orderlist = res.data.data
       // alert(res.data.data);
        this.orderlistlength = this.orderlist.length

      })
    },
    juorder () {
      var id = localStorage.getItem('ZZId');
      costManager(id, this.currentPage2, this.pagesize2, 2).then(res => {
        console.log(res)
        this.total2 = res.data.total
        this.orderlist1 = res.data.data
        this.orderlistlength1 = this.orderlist1.length
        console.log(this.orderlist1)
      })
    },
    qrorder () {
      var id = localStorage.getItem('ZZId');
      costManager(id, this.currentPage3, this.pagesize3, 1).then(res => {
        console.log(res)
        this.total3 = res.data.total
        this.orderlist2 = res.data.data
        this.orderlistlength2 = this.orderlist2.length
      })
    }
  }
}
</script>

<style>
.orderlist {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.biaoti {
  font-size: 14px;
}

.orderlist span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0 3px;
}

.urgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: #ff1918;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
.nourgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: lightseagreen;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
.who {
  position: absolute;
  right: 50px;
  top: 0;
  width: 40px;
  height: 24px;
  background: #2f7df7;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  padding: 0 5px;
}

.ygdPage {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ygdright {
  width: 100rpx;
}
</style>