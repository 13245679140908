import request from '@/utils/request'
import qs from 'qs'

export function costManager (authorId,page,limit,typeId) {
    let data = {
        authorId,page,limit,typeId
    }
    data = qs.stringify(data)
    return request({
        url: '/other/costInfo',
        method: 'post',
        data
    })
}